@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;500;700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,700;1,800&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300&family=Public+Sans:wght@300;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    font-family: 'Montserrat';
    overflow-x: hidden;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style-type: none;
    /* overflow-block: hidden; */
}
.header{
    background: url('./assets/imgs/header.png');
}
.card{
    background: linear-gradient(90.25deg, rgba(26, 24, 23, 0.95) 0.38%, rgba(177, 128, 82, 0.71) 77.99%, #252322 103.93%, #23211F 107.84%);
}